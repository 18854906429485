<template>
    <ui-async :guards="timeline">
        <div class="flow-root">
            <ul role="list">
                <li v-for="(activityItem, activityItemIdx) in list()" :key="activityItem.id">
                    <div class="relative pb-4">
                        <span v-if="activityItemIdx !== timeline.length - 1" class="absolute left-4 -ml-px w-px bg-gray-300" style="height: calc(100% - 46px); top: 40px" aria-hidden="true" />

                        <div class="flex space-x-2 mt-1">
                            <div class="relative">
                                <div v-if="activityItem?.icon" class="flex h-8 w-8 items-center justify-center rounded-full mt-1">
                                    <ui-icon :name="activityItem?.icon"></ui-icon>
                                </div>

                                <div v-else class="w-8 h-8 bg-gray-300 rounded-full "></div>
                            </div>

                            <div class="min-w-0 flex items-start flex-1">
                                <ui-avatar :item="activityItem.causer" class="h-8 w-8 rounded-full bg-gray-400"></ui-avatar>

                                <div class="flex-1 pl-2">
                                    <div class="leading-snug">
                                        <span class="font-medium text-gray-100 font-medium">{{ activityItem.causer.name }}</span>
                                    </div>

                                    <div class="text-gray-200 text-sm w-full" v-if="activityItem.type == 'reported'">
                                        <span v-if="activityItem.report?.updateSummary">Added new information</span>
                                        <span v-else>Reported</span>

                                        <span class="text-gray-300 text-sm float-right">
                                            {{ $dateTime(activityItem.createdAt) }}
                                        </span>
                                    </div>

                                    <div class="mt-1 text-sm text-gray-200" v-if="activityItem.report?.updateSummary">
                                        <p class="italic">{{ activityItem.report.updateSummary }}</p>
                                    </div>

                                    <div class="text-gray-200 text-sm flex items-center space-x-2" v-else-if="activityItem.type == 'sentinel.events.tag-added'">
                                        <span>Added tag</span>
                                        <div class="inline-flex items-center bg-gray-600 text-gray-100 font-medium inline-block px-2 py-0.5 rounded">
                                            <div class="w-2.5 h-2.5 rounded-full inline-block mr-2" :class="`bg-${activityItem.tag?.color}`"></div>
                                            <span>{{activityItem.tag.name}}</span>
                                        </div>
                                    </div>
                                    <div class="text-gray-200 text-sm flex items-center space-x-2" v-else-if="activityItem.type == 'sentinel.events.tag-removed'">
                                        <span>Removed tag</span>
                                        <div class="inline-flex items-center bg-gray-600 text-gray-100 font-medium inline-block px-2 py-0.5 rounded">
                                            <div class="w-2.5 h-2.5 rounded-full inline-block mr-2" :class="`bg-${activityItem.tag?.color}`"></div>
                                            <span>{{activityItem.tag.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </ui-async>
</template>


<script>
import useDetailsStore from "@/stores/overview/details";
import { mapState } from 'pinia'

export default {
    props: [ 'limit' ],

    computed: {
        ...mapState(useDetailsStore, [ 'timeline' ]),
    },

    methods: {
        list() {
            if (this.limit) {
                return this.timeline.slice(0, this.limit)
            }

            return this.timeline
        }
    }
}
</script>
