<template>
    <div class="flex items-center justify-between pt-2 px-5">
        <h1 class="text-sm text-gray-400 font-medium">Events</h1>
        <a href="#" @click.prevent="hideDetails">
            <ui-icon name="x"></ui-icon>
        </a>
    </div>
    
    <div>
        <h2 class="px-5 text-3xl text-gray-100 font-medium mt-4 flex items-center justify-between">
            <span v-if="mapLayers.length == 1">1 Layer</span>
            <span v-else-if="mapLayers.length > 1">{{ mapLayers.length }} Layers</span>
            <span v-else-if="cluster">Selected Cluster</span>
            <span v-else>Worldwide</span>
        </h2>

        <div class="flex items-center space-x-5 border-b border-gray-500 px-5 mt-3">
            <a href="#" @click.prevent="shownTab = 'overview'" :class="shownTab == 'overview' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                Overview
            </a>
            <a href="#" @click.prevent="shownTab = 'events'" :class="shownTab == 'events' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                Events
            </a>
<!--            <a href="#" @click.prevent="shownTab = 'stream'" :class="shownTab == 'stream' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">-->
<!--                Content-->
<!--            </a>-->
<!--            <a href="#" @click.prevent="shownTab = 'trends'" :class="shownTab == 'trends' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium" v-if="trends.length">-->
<!--                Trends-->
<!--            </a>-->
        </div>
    </div>

    <div class="flex-1 min-h-0 overflow-auto">
        <overview-tab v-if="shownTab == 'overview'"></overview-tab>
        <events-tab v-if="shownTab == 'events'"></events-tab>
<!--        <stream-tab v-if="shownTab == 'stream'"></stream-tab>-->
<!--        <trends-tab v-if="shownTab == 'trends'"></trends-tab>-->
    </div>
</template>

<script>
import EventsTab from './results/events-tab'
import OverviewTab from './results/overview-tab'
// import StreamTab from './results/stream-tab'
// import TrendsTab from './results/trends-tab'

import useDetailsStore from '@/stores/overview/details'
import useOverviewStore from '@/stores/overview/overview'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        EventsTab, OverviewTab,
        // StreamTab, TrendsTab
    },
    
    computed: {
        ...mapState(useDetailsStore, [ 'cluster', 'trends' ]),
        ...mapState(useOverviewStore, [ 'mapLayers' ]),
        ...mapWritableState(useDetailsStore, [ 'shownTab' ])
    },

    methods: {
        ...mapActions(useOverviewStore, [ 'hideDetails' ])
    }
}
</script>