<template>
    <div v-for="node in nodes" :key="node.id" class="px-1 mb-1">
        <div class="flex px-4 items-center rounded leading-5 focus:outline-none transition duration-150 ease-in-out h-7 text-sm text-gray-300 hover:bg-gerulata-green-500 hover:text-white">

            <div v-if="node.subcategories?.length" @click="toggleExpand(node)" class="text-gray-200 cursor-pointer w-4 inline-block text-left">
                {{ isExpanded[node.id] ? '▼' : '▶' }}
            </div>

            <input
                type="checkbox"
                class="h-4 w-4 text-gerulata-green-700 border-gray-600 bg-gray-700 border-transparent focus:border-transparent focus:ring-0"
                :class="{ 'ml-4': !node.subcategories?.length }"
                :id="node.id"
                :indeterminate="forceIndeterminate || indeterminate(node)"
                :checked="checkedNodes.includes(node.id)"
                @change="onCheckboxChange(node)"
            />

            <div class="rounded-full w-3 h-3 opacity-80 inline-block ml-2" :style="{ backgroundColor: node?.color }"></div>
            <label :for="node.id" class="ml-2">{{ node.name }}</label>
        </div>

        <div class="ml-4" v-if="node.subcategories" v-show="isExpanded[node.id]">
            <categories-tree-view
                :force-indeterminate="checkedNodes.includes(node.id)"
                :nodes="node.subcategories"
                :checked-nodes="checkedNodes"
                @change="subcategoryChange"
            ></categories-tree-view>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        nodes: Array,
        checkedNodes: Object,
        forceIndeterminate: Boolean
    },

    emits : ['change'],

    data() {
        return {
            isExpanded: {},
            intermediateNodes: [],
        }
    },

    methods: {
        toggleExpand(node) {
            this.isExpanded[node.id] = !this.isExpanded[node.id]
        },

        onCheckboxChange(node) {
            this.$emit('change', node)
        },

        subcategoryChange(node) {
            this.onCheckboxChange(node)
        },

        indeterminate(node) {
            return !this.checkedNodes.includes(node.id) &&
                node.subcategories?.length &&
                node.subcategories.some(sub => this.checkedNodes.includes(sub.id) || this.indeterminate(sub))
        }
    },
}
</script>
