<template>
    <search-filter name="Category" :ids="['category']" :filters="filters" @opened="reset">
        <template v-slot:applied>
            <div class="flex items-center">
                <div class="flex items-center" v-if="value.length">
                    <div class="rounded-full w-3 h-3 opacity-80 mr-1" :style="{ backgroundColor: category?.color }" v-if="value.length === 1"></div>

                    <span class="font-medium" v-if="value.length > 1">
                        {{ value.length }} categories
                    </span>

                    <span class="font-medium" v-else>
                        {{ category.name }}
<!--                        <span v-if="subcategory">- {{ subcategory.name }}</span>-->
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-96 max-h-96 overflow-y-auto">
                <categories-tree-view :nodes="categories" :checked-nodes="localValues" @change="toggleValue" />
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center text-gray-200" @click="filters.remove('category'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="green" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useEventCategories from '@/stores/me/event-categories'
import CategoriesTreeView from '@/components/overview/toolbar/filters/categories-tree-view'

import { mapActions, mapState } from 'pinia'

export default {
    components: { SearchFilter, CategoriesTreeView },

    props: [ 'filters' ],

    data: () => ({
        localValues: []
    }),

    computed: {
        ...mapState(useEventCategories, [ 'availableCategories', 'flatCategories', 'categories' ]),

        value() {
            return this.filters.value('category') ?? []
        },

        category() {
            let category = this.findCategory(this.value[0])
            // TODO improve this
            return category.parent?.parent || category.parent || category
        },

        subcategory() {
            return this.flatCategories.find(c => c.id == this.value[1])
        }
    },

    methods: {
        ...mapActions(useEventCategories, { findCategory: 'find' }),

        toggleValue(item) {
            if (item.parent && this.hasCategorySelected(item.parent.id)) {
                this.localValues = this.localValues.filter(v => v != item.parent.id)
            }

            if (! item.parent) {
                let subcategories = item.subcategories.map(c => c.id)
                this.localValues = this.localValues.filter(v => ! subcategories.includes(v))
            }

            this.localValues.includes(item.id)
                ? this.localValues.splice(this.localValues.findIndex(v => v == item.id), 1)
                : this.localValues.push(item.id)
        },

        hasCategorySelected(categoryId) {
            return this.localValues.includes(categoryId)
        },

        reset() {
            this.localValues = this.filters.value('category') ? [ ...this.filters.value('category') ] : []
            this.search = ''
        },

        apply() {
            this.localValues.length
                ? this.filters.set('category', this.localValues)
                : this.filters.remove('category')
        }
    }
}
</script>
