<template>
  <div class="relative">
    <div class="flex h-screen w-full">
      <div class="h-screen w-full bg-neutral-900" id="channel-map">
      </div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <div
            class="mx-auto max-w-2xl transform divide-y divide-gray-500/20 overflow-hidden rounded-xl bg-gray-900 shadow-2xl transition-all">
          <div class="grid grid-cols-1">
            <input type="text"
                   class="col-start-1 row-start-1 h-12 w-full bg-transparent pl-11 pr-4 text-base text-white outline-none placeholder:text-gray-500 sm:text-sm border-none"
                   placeholder="Search..." role="combobox" aria-expanded="false" aria-controls="options">
            <svg class="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-500"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
              <path fill-rule="evenodd"
                    d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
                    clip-rule="evenodd"/>
            </svg>
          </div>

          <ul class="max-h-80 scroll-py-2 divide-y divide-gray-500/20 overflow-y-auto">
            <li class="p-2">
              <h2 class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-200">Recent searches</h2>
              <ul class="text-sm text-gray-400">
                <!-- Active: "bg-gray-800 text-white outline-none" -->
                <li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
                  <!-- Active: "text-white forced-colors:text-[Highlight]", Not Active: "text-gray-500" -->
                  <svg class="size-6 flex-none text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"/>
                  </svg>
                  <span class="ml-3 flex-auto truncate">Workflow Inc. / Website Redesign</span>
                  <!-- Not Active: "hidden" -->
                  <span class="ml-3 hidden flex-none text-gray-400">Jump to...</span>
                </li>
              </ul>
            </li>
            <li class="p-2">
              <h2 class="sr-only">Quick actions</h2>
              <ul class="text-sm text-gray-400">
                <!-- Active: "bg-gray-800 text-white outline-none" -->
                <li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
                  <!-- Active: "text-white forced-colors:text-[Highlight]", Not Active: "text-gray-500" -->
                  <svg class="size-6 flex-none text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                  </svg>
                  <span class="ml-3 flex-auto truncate">Add new file...</span>
                  <span class="ml-3 flex-none text-xs font-semibold text-gray-400"><kbd class="font-sans">⌘</kbd><kbd
                      class="font-sans">N</kbd></span>
                </li>
                <li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
                  <svg class="size-6 flex-none text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"/>
                  </svg>
                  <span class="ml-3 flex-auto truncate">Add new folder...</span>
                  <span class="ml-3 flex-none text-xs font-semibold text-gray-400"><kbd class="font-sans">⌘</kbd><kbd
                      class="font-sans">F</kbd></span>
                </li>
                <li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
                  <svg class="size-6 flex-none text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5"/>
                  </svg>
                  <span class="ml-3 flex-auto truncate">Add hashtag...</span>
                  <span class="ml-3 flex-none text-xs font-semibold text-gray-400"><kbd class="font-sans">⌘</kbd><kbd
                      class="font-sans">H</kbd></span>
                </li>
                <li class="group flex cursor-default select-none items-center rounded-md px-3 py-2">
                  <svg class="size-6 flex-none text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"/>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z"/>
                  </svg>
                  <span class="ml-3 flex-auto truncate">Add label...</span>
                  <span class="ml-3 flex-none text-xs font-semibold text-gray-400"><kbd class="font-sans">⌘</kbd><kbd
                      class="font-sans">L</kbd></span>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {useChannelMapStore} from '@/stores/channels/channel-map'
import 'mapbox-gl/dist/mapbox-gl.css'
import api from "@/api";

export default {
  components: {},

  computed: {},

  async mounted() {

    const channelMapStore = useChannelMapStore();

    if (this.$route.name === 'channels') {
      await useChannelMapStore().showChannelsLocation()
    }

    if (this.$route.name === 'channels.detail') {
      const channelId = this.$route.params.channelId
      const channelDetailData = await api
          .route('sentinel channels detail', { channelId })
          .get()
          .json()
      await useChannelMapStore().showChannelDetail(channelDetailData)
    }
  }
}
</script>
