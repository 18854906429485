import useMyStore from '@/stores/me/my'

import api from '@/api'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export const useMyQuickSearchStore = defineStore({
    id: 'myQuickSearch',

    state: () => ({
        isShown: false,
        inMaintenance: false,

        search: '',
        limit: 3,

        initialResults: [],
        results: [],
        resultsPending: false,
        resultsPromise: null,

        hasFilterableWorkspaces: true,
        workspace: null,

        hasFilterableFamilies: true,
        filteredFamilies: [],
        families: [],

        onSelect: null,
        onHide: null
    }),

    getters: {
        filteredResults(state) {
            return state.filteredFamilies.length ? this.results.filter(r => state.filteredFamilies.includes(r.family)) : this.results
        }
    },

    actions: {
        initialize() {
        },

        show(options) {
            this.$reset()

            this.isShown = true

            this.hasFilterableFamilies = options?.hasFilterableFamilies || false
            this.hasFilterableWorkspaces = options?.hasFilterableWorkspaces || false
            this.workspace = options?.workspace ?? useMyStore().currentWorkspace
            this.families = options?.families || [ 'analyses', 'app', 'channels', 'dashboards', 'features', 'lists', 'perspectives', 'topics' ]
            this.limit = options?.limit || 3
            this.onSelect = options?.onSelect
            this.onHide = options?.onHide
            this.results = this.initialResults = options?.initialResults || []
        },

        hide() {
            if (this.onHide) this.onHide()

            this.isShown = false
        },

        loadDebounced: debounce(function () { this.load() }, 150),

        async load() {
            if (this.search.length == 0) this.results = this.initialResults
            if (this.search.length < 3) return

            if (this.resultsPromise) {
                this.abortGeneralLoad?.abort()
                this.abortWorkspaceLoad?.abort()
            }

            this.resultsPending = true
            this.inMaintenance = false

            let generalSearch = Promise.resolve()
            let userSearch = Promise.resolve()

            if (this.shouldSearch([ 'channels' ])) {
                generalSearch = api.url(`${process.env.VUE_APP_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortGeneralLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().currentWorkspace.keys.quickSearchGeneral })
                    .options({ credentials: 'same-origin' })
                    .json({
                        searches: [
                            this.shouldSearch('app') ? {
                                collection: 'app',
                                q: this.search,
                                query_by: 'title',
                                per_page: this.limit
                            } : null,
                            this.shouldSearch('channels') ? {
                                collection: 'channels',
                                q: this.search,
                                query_by: 'title,username',
                                sort_by: 'followers:desc,_text_match:desc',
                                group_by: 'type',
                                per_page: 100,
                                group_limit: this.limit
                            } : null,
                            // this.shouldSearch('features') ? {
                            //     collection: 'features',
                            //     q: this.search,
                            //     query_by: 'title',
                            //     group_by: 'type',
                            //     per_page: 100,
                            //     group_limit: this.limit
                            // } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            if (this.shouldSearch([ 'analyses', 'dashboards', 'lists', 'perspectives', 'topics' ])) {
                let query = { 'query_by': 'title' }

//                if (this.workspace) {
//                    query.filter_by = `workspaceId:[${this.workspace.id}]`
//                }

                userSearch = api.url(`${process.env.VUE_APP_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortWorkspaceLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().user.keys.quickSearchUser })
                    .options({ credentials: 'same-origin' })
                    .query(query)
                    .json({
                        searches: [
                            this.shouldSearch('analyses') ? { collection: 'analyses', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('dashboards') ? { collection: 'dashboards', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('lists') ? { collection: 'lists', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('perspectives') ? { collection: 'perspectives', q: this.search, per_page: this.limit, filter_by: 'product:juno' } : null,
                            this.shouldSearch('topics') ? { collection: 'topics', q: this.search, per_page: this.limit } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            return this.resultsPromise = Promise.all([ generalSearch, userSearch ])
                .then(([ generalData, workspaceData ]) => {
                    if (! generalData && ! workspaceData) return

                    this.results = [
                        ...(! this.families.length ? [{ resultType: 'perspective-new' }] : []),
                        ...this.familyResults(workspaceData, 'analyses').hits.map(result => ({ ...result.document, resultType: 'analysis', family: 'analyses' })),
                        ...this.familyResults(generalData, 'app').hits.map(result => ({ ...result.document, resultType: 'app', family: 'app' })),
                        ...this.familyResults(workspaceData, 'dashboards').hits.map(result => ({ ...result.document, resultType: 'dashboard', family: 'dashboards' })),
                        ...this.familyResults(workspaceData, 'perspectives').hits.map(result => ({ ...result.document, resultType: 'perspective', family: 'perspectives' })),
                        ...this.familyResults(workspaceData, 'lists').hits.map(result => ({ ...result.document, resultType: 'list', family: 'lists' })),
                        ...this.familyResults(workspaceData, 'topics').hits.map(result => ({ ...result.document, resultType: 'topic', family: 'topics' })),
                        ...this.familyResults(generalData, 'channels').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'channel', family: 'channels' }))),
                        ...this.familyResults(generalData, 'features').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'feature', family: 'features' })))
                    ]

                    this.resultsPending = false
                    this.resultsPromise = null
                    this.abortGeneralLoad = this.abortWorkspaceLoad = null
                })
        },

        clear() {
            this.$reset()
        },

        resetFilteredFamilies() {
            this.filteredFamilies = []
            this.limit = 3

            this.load()
        },

        setFilteredFamilies(families) {
            this.filteredFamilies = families.filter(family => this.families.includes(family))

            this.limit = 50
            this.load()
        },

        isFamilyIncludedInFilter(family) {
            return this.filteredFamilies.includes(family)
        },

        resetWorkspace() {
            this.workspace = false

            this.loadDebounced()
        },

        setWorkspace(workspace) {
            this.workspace = workspace

            this.loadDebounced()
        },

        shouldSearch(family) {
            if (family instanceof Array) return family.some(f => this.shouldSearch(f))
            return this.families.includes(family) && (! this.filteredFamilies.length || this.filteredFamilies.includes(family))
        },

        familyResults(data, family) {
            if (! data) return { hits: [], grouped_hits: [] }
            return data.results.find(r => r.request_params.collection_name == family) || { hits: [], grouped_hits: [] }
        }
    }
})

export default useMyQuickSearchStore
