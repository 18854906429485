<template>
    <div>
        <ui-async :guards="event">
            <div class="bg-cover bg-no-repeat bg-opacity-90 rounded-t-md [text-shadow:_0_2px_6px_var(--tw-shadow-color)] shadow-gray-900"
                 :style="{ backgroundImage: event.image ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${event.image})` : 'none', opacity: 0.9 }"
            >
                <div class="items-center py-3 px-5">
                    <a href="#" @click.prevent="hideDetails" class="float-right">
                        <ui-icon name="x"></ui-icon>
                    </a>
                    <a v-if="events.data?.length > 1" href="#" class="float-left mr-1 font-bold" @click.prevent="hideEvent" title="Back to event list">
                        <ui-icon name="arrow-left"></ui-icon>
                    </a>
                    <h1 class="text-sm font-medium">Event</h1>
                </div>
    
                <div class="px-5 mt-10">
                    <div class="w-12 h-12 bg-gray-300 float-right rounded-full justify-center items-center ml-2" :style="{ backgroundColor: category?.color }">
                        <img :src="category?.icon" class="w-8 mx-auto mt-2 opacity-80">
                    </div>
                </div>

                <h2 class="px-5 text-3xl text-gray-100 font-medium mt-10">{{ event.title }}</h2>
    
                <div class="clear-both"></div>
    
                <div class="flex justify-between space-x-5 border-b border-gray-500 px-5 mt-12">
                    <div class="flex items-center space-x-5">
                        <a href="#" @click.prevent="shownTab = 'overview'" :class="shownTab == 'overview' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                            Overview
                        </a>
<!--                        <a href="#" @click.prevent="shownTab = 'timeline'" :class="shownTab == 'timeline' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">-->
<!--                            Timeline-->
<!--                        </a>-->
                        <a href="#" @click.prevent="shownTab = 'channels'" :class="shownTab == 'channels' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                            Channels
                        </a>
                        <a href="#" @click.prevent="shownTab = 'content'" :class="shownTab == 'content' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                            Content
                        </a>
                        <a href="#" @click.prevent="shownTab = 'mentions'" :class="shownTab == 'mentions' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                          Mentions
                        </a>
                        <a href="#" @click.prevent="shownTab = 'photos'" :class="shownTab == 'photos' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">
                            Photos
                        </a>
<!--                        <a href="#" @click.prevent="shownTab = 'metrics'" :class="shownTab == 'metrics' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium">-->
<!--                            Metrics-->
<!--                        </a>-->
                    </div>
                    <div class="flex items-center space-x-5">
                        <a href="#" @click.prevent="shownTab = 'debug'" :class="shownTab == 'debug' ? 'text-gerulata-green-100 border-gerulata-green-100' : 'border-transparent'" class="py-2 border-b-2 font-medium" v-tooltip="'Debug'">
                            <ui-icon name="tool"></ui-icon>
                        </a>
                        <ui-dropdown z-index="50">
                            <template v-slot:trigger>
                                <a href="#" class="border-b-2 border-transparent">
                                    <ui-icon name="tag" v-tooltip="'Tagging'"></ui-icon>
                                </a>
                            </template>
                            <template v-slot:content>
                                <div @click.stop>
                                    <tagging-menu-content :item="event"></tagging-menu-content>
                                </div>
                            </template>
                        </ui-dropdown>
                        <a href="#" @click.prevent="copyLink(event.id)" class="border-b-2 border-transparent" v-tooltip="'Copy event link'">
                            <ui-icon name="share"></ui-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ui-async>
    </div>

    <div class="flex-1 min-h-0 overflow-auto">
        <overview-tab v-if="shownTab == 'overview'"></overview-tab>
<!--        <timeline-tab v-if="shownTab == 'timeline'"></timeline-tab>-->
        <channels-tab v-if="shownTab == 'channels'"></channels-tab>
        <content-tab v-if="shownTab == 'content'"></content-tab>
        <mentions-tab v-if="shownTab == 'mentions'"></mentions-tab>
        <photos-tab v-if="shownTab == 'photos'"></photos-tab>
<!--        <metrics-tab v-if="shownTab == 'metrics'"></metrics-tab>-->
        <debug-tab v-if="shownTab == 'debug'"></debug-tab>
    </div>
</template>

<script>
import DebugTab from './event/debug-tab'
// import MetricsTab from './event/metrics-tab'
import OverviewTab from './event/overview-tab'
import PhotosTab from './event/photos-tab'
import ChannelsTab from './event/channels-tab'
import ContentTab from './event/content-tab'
import MentionsTab from './event/mentions-tab'
import TaggingMenuContent from '@/components/me/tags/contextual-menu-content'
// import TimelineTab from './event/timeline-tab'
import useDetailsStore from '@/stores/overview/details'
import useEventCategoriesStore from '@/stores/me/event-categories'
import useMyNotificationsStore from '@/stores/me/notifications'
import useOverviewStore from '@/stores/overview/overview'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {

    components: {
        DebugTab, OverviewTab, PhotosTab, ChannelsTab, ContentTab, MentionsTab, TaggingMenuContent,
        // TimelineTab, MetricsTab
    },

    computed: {
        ...mapState(useDetailsStore, [ 'cluster', 'event', 'events' ]),
        ...mapWritableState(useDetailsStore, [ 'shownTab' ]),

        category() { return useEventCategoriesStore().find(this.event.category) }
    },

    methods: {
        ...mapActions(useDetailsStore, [ 'hideEvent' ]),
        ...mapActions(useOverviewStore, [ 'hideDetails', 'showDetails' ]),

        copyLink(eventId) {
            navigator.clipboard.writeText(window.location.origin + '/overview/event/' + eventId)
            useMyNotificationsStore().pushToast({
                type: 'info',
                title: 'Event link copied to clipboard',
                lifetime: 2
            })
        }
    }
}
</script>
