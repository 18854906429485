import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import mapboxgl from 'mapbox-gl'

// Deck.GL
import { Deck } from '@deck.gl/core'
import { MapboxLayer } from '@deck.gl/mapbox'
import { ArcLayer } from '@deck.gl/layers'

// API import
import api from '@/api'

export const useChannelMapStore = defineStore('channel-map', {
    state: () => ({
        map: null,
        deckInstance: null,
        channelsData: null,
        channelDetailData: null,
        currentView: null,
    }),

    actions: {

        async initMap() {
            if (this.map) {
                return this.map
            }

            return new Promise((resolve) => {
                const map = new mapboxgl.Map({
                    accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
                    container: 'channel-map',
                    style: process.env.VUE_APP_MAPBOX_STYLE,
                    center: [17.1077, 48.1486],
                    zoom: 3,
                    minZoom: 3,
                    maxZoom: 18,
                    bearing: 0,
                    pitch: 45,
                    projection: { name: 'mercator' },
                })

                map.on('load', () => {
                    this.map = markRaw(map)
                    resolve(this.map)
                })
            })
        },

        async showChannelsLocation() {
            this.currentView = 'locations'
            await this.initMap()

            this.removeAllLayers()

            try {
                const response = await api.route('sentinel channels locations', {}).get().json()
                this.channelsData = response

                this.map.addSource('sentinel-channels', {
                    type: 'geojson',
                    data: this.channelsData,
                })

                this.map.addLayer({
                    id: 'sentinel-channel-locations',
                    type: 'circle',
                    source: 'sentinel-channels',
                    paint: {
                        'circle-radius': [
                            'interpolate',
                            ['linear'],
                            ['get', 'count'],
                            0, 3,
                            10, 6,
                            50, 12,
                            100, 20,
                        ],
                        'circle-color': '#49B0FF',
                    },
                })
            } catch (error) {
                console.error('Error fetching channel locations:', error)
            }
        },

        async showChannelDetail(channelDetailData) {
            this.currentView = 'detail'
            await this.initMap()

            this.removeAllLayers()

            try {
                const arcs = channelDetailData.reports.map(report => ({
                    sourcePosition: [channelDetailData.lng, channelDetailData.lat],
                    targetPosition: [report.lng, report.lat],
                    count: report.count,
                }))

                const arcLayer = new ArcLayer({
                    id: 'arc-layer',
                    data: arcs,
                    getSourcePosition: d => d.sourcePosition,
                    getTargetPosition: d => d.targetPosition,
                    getSourceColor: [73, 176, 255],
                    getTargetColor: [6, 239, 85],
                    getWidth: d => Math.sqrt(d.count),
                })

                this.deckInstance = markRaw(new Deck({
                    gl: this.map.painter.context.gl,
                    layers: [arcLayer],
                    initialViewState: {
                        longitude: 17.1077,
                        latitude: 48.1486,
                        zoom: 3,
                        pitch: 45,
                        bearing: 0,
                    },
                    controller: false,
                }))

                const deckLayer = new MapboxLayer({ id: 'deck-gl-layer', deck: this.deckInstance })
                this.map.addLayer(deckLayer)
            } catch (error) {
                console.error('Error fetching channel detail:', error)
            }
        },

        removeAllLayers() {
            if (!this.map) return

            if (this.map.getLayer('deck-gl-layer')) {
                this.map.removeLayer('deck-gl-layer')
            }

            if (this.map.getLayer('sentinel-channel-locations')) {
                this.map.removeLayer('sentinel-channel-locations')
            }

            if (this.map.getSource('sentinel-channels')) {
                this.map.removeSource('sentinel-channels')
            }

            if (this.deckInstance) {
                this.deckInstance.setProps({ layers: [] })
            }
        },
    },
})
