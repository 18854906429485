<template>
    <div class="space-y-4 pb-4">
        <div v-for="event in events" :key="event.id" class="flex items-start space-x-5 leading-tight cursor-pointer" @click="inspectEvents({ event, center: [-250, 0] })">
            <div>
                <img v-if="event.image" :src="event.image" class="block w-20 h-20 rounded-md bg-gray-400 object-cover">
                <div v-else :class="`block pt-5 w-20 h-20 object-cover rounded-md`" :style="{ backgroundColor: findCategory(event.category)?.color }">
                    <img :src="findCategory(event.category)?.icon" class="w-10 mx-auto opacity-80">
                </div>
            </div>

            <div class="flex-1">
                <div class="font-semibold " :class="event.isNew ? 'text-gerulata-green-100' : 'text-gray-100'">
                    {{ event.title }}
                </div>

                <div class="flex items-center space-x-1 mt-1 text-sm text-gray-300">
                    <div class="w-3 h-3 rounded-full mr-1" :style="{ backgroundColor: findCategory(event.category)?.color }"></div>
                    <span>{{ findCategory(event.category)?.name }}</span>
                </div>

                <div class="text-sm text-gray-300">
                    {{ event.location.country }}
                </div>
            </div>

            <div class="text-right pt-0.5 text-gray-300">
                <span class="whitespace-nowrap">{{ $distanceTime(event.startedOn) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import useEventCategoriesStore from '@/stores/me/event-categories'
import useOverviewStore from '@/stores/overview/overview'

import { mapActions } from 'pinia'

export default {
    props: [ 'events' ],

    methods: {
        ...mapActions(useEventCategoriesStore, { findCategory: 'find' }),
        ...mapActions(useOverviewStore, [ 'inspectEvents' ])
    }
}
</script>
