<script setup>
const BaseMapLayers = [
  {
    name: 'Mapbox Satellite',
    desc: 'De-clouded, visible spectrum satellite imagery based on NASA Modis, Landsat and Maxar Vivid data, combined with Vexcel aerial imagery in major cities.',
    role: 'Base Map',
    imageUrl:
        'map/maxar.png',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Sentinel Default',
    desc: 'De-clouded, visible spectrum satellite imagery based on NASA Modis, Landsat and Maxar Vivid data, combined with Vexcel aerial imagery in major cities.',
    role: 'Base Map',
    imageUrl:
        'map/default.png',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Critical Infrastructure',
    desc: 'Vector map highlighting critical energy and transportation infrastructure.',
    role: 'Base Map',
    imageUrl:
        'map/infra.png',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  }
]

const ImageryLayers = [
  {
    name: 'Gaza Strip / Multispectral 10m Sentinel-2',
    desc: 'Multispectral, 10 metre resolution Sentinel-2 image of the Gaza Strip, refreshed daily.',
    role: 'Imagery',
    imageUrl:
        'map/multispectral.png',
    lastUpdate: '16h ago'
  }
]

const GridLayers = [
  {
    name: 'Seniment Polarity',
    desc: 'Average sentiment polarity in Gerulata Juno content referencing the area. Data aggregated by H3 grid index.',
    role: 'Grid Data',
    imageUrl:
        'map/h3.png',
    lastUpdate: '16h ago'
  }
]

const LiveLayers = [
  {
    name: 'ADS-B Exchange: Military Aircraft',
    desc: 'Positions of military aircraft as detected by ADS/B transponders. Sourced from ADSBExchange.',
    role: 'Live Position',
    imageUrl:
        'map/adsb.png',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  },
  {
    name: 'Celestrak: Surveillance Satellites',
    desc: 'Positions and footprints of known surveillance satellites, sourced from Celestrak.',
    role: 'Live Position',
    imageUrl:
        'map/sat.png',
    lastSeen: '3h ago',
    lastSeenDateTime: '2023-01-23T13:23Z',
  }
]
</script>

<template>
  <div class="relative">

    <div class="pl-32 pt-16 flex justify-center w-1/2">
      <div class="flex items-center justify-center bg-gray-500 px-6 py-3 sm:rounded-xl">
        <p class="text-sm text-white">
          <strong class="font-semibold">Note:</strong> This section is currently disabled and will become available in one of the future releases.
        </p>
      </div>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Base Map Layers</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Base map layers are provided in the form of Mapbox Styles.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <button type="button" class="inline-flex items-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-200 shadow-sm">Add new Base Map layer</button>
      </div>
    </div>

    <div class="flex pl-32 pt-16 w-1/2">
      <ul role="list" class="divide-y divide-gray-800 w-full">
        <li v-for="layer in BaseMapLayers" :key="layer.name" class="flex justify-between gap-x-6 py-5">
          <div class="flex min-w-0 gap-x-4">
            <img class="w-12 h-12 flex-none rounded-full bg-gray-800" :src="layer.imageUrl" alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm/6 font-semibold text-white">{{ layer.name }}</p>
              <p class="mt-1 text-xs/5 text-gray-400">{{ layer.desc }}</p>
            </div>
          </div>
          <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p class="text-sm/6 text-white">{{ layer.role }}</p>
            <div class="mt-1 flex items-center gap-x-1.5">
              <div class="flex-none rounded-full bg-emerald-500 p-1">
                <div class="size-1.5 rounded-full bg-emerald-500" />
              </div>
              <p class="text-xs/5 text-gray-400">Enabled</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Imagery Layers</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Custom geo-referenced raster imagery data.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <button type="button" class="inline-flex items-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-200 shadow-sm">Add new Imagery layer</button>
      </div>
    </div>

    <div class="flex pl-32 pt-16 w-1/2">
      <ul role="list" class="divide-y divide-gray-800 w-full">
        <li v-for="layer in ImageryLayers" :key="layer.name" class="flex justify-between gap-x-6 py-5">
          <div class="flex min-w-0 gap-x-4">
            <img class="w-12 h-12 flex-none rounded-full bg-gray-800" :src="layer.imageUrl" alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm/6 font-semibold text-white">{{ layer.name }}</p>
              <p class="mt-1 text-xs/5 text-gray-400">{{ layer.desc }}</p>
            </div>
          </div>
          <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p class="text-sm/6 text-white">{{ layer.role }}</p>
            <div class="mt-1 flex items-center gap-x-1.5">
              <div class="flex-none rounded-full bg-gray-500 p-1">
                <div class="size-1.5 rounded-full bg-gray-500" />
              </div>
              <p class="text-xs/5 text-gray-400">Last updated {{ layer.lastUpdate }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Grid Data Layers</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Custom data referenced by H3 grid index.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <button type="button" class="inline-flex items-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-200 shadow-sm">Add new Grid Data layer</button>
      </div>
    </div>

    <div class="flex pl-32 pt-16 w-1/2">
      <ul role="list" class="divide-y divide-gray-800 w-full">
        <li v-for="layer in GridLayers" :key="layer.name" class="flex justify-between gap-x-6 py-5">
          <div class="flex min-w-0 gap-x-4">
            <img class="w-12 h-12 flex-none rounded-full bg-gray-800" :src="layer.imageUrl" alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm/6 font-semibold text-white">{{ layer.name }}</p>
              <p class="mt-1 text-xs/5 text-gray-400">{{ layer.desc }}</p>
            </div>
          </div>
          <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p class="text-sm/6 text-white">{{ layer.role }}</p>
            <div class="mt-1 flex items-center gap-x-1.5">
              <div class="flex-none rounded-full bg-gray-500 p-1">
                <div class="size-1.5 rounded-full bg-gray-500" />
              </div>
              <p class="text-xs/5 text-gray-400">Last updated {{ layer.lastUpdate }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Geography Layers</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Custom vector geography features, such as points-of-interest, areas, etc.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <button type="button" class="inline-flex items-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-200 shadow-sm">Add new Geography layer</button>
      </div>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Narrative Layers</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Narrative intelligence referencing an area of interest, captured and processed by Gerulata Juno.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
      </div>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Situations Layer</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Real world situations affecting a wider geographic area, as detected by Gerulata Sentinel.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
      </div>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Events Layer</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Real world events detected by Gerulata Sentinel.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
      </div>
    </div>

    <div class="flex w-1/2 pl-32 pt-16">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight">Live Position Layers</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-300">Live positional data refreshed in real-time.</p>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0">
      </div>
    </div>

    <div class="flex pl-32 pt-16 w-1/2">
      <ul role="list" class="divide-y divide-gray-800 w-full">
        <li v-for="layer in LiveLayers" :key="layer.name" class="flex justify-between gap-x-6 py-5">
          <div class="flex min-w-0 gap-x-4">
            <img class="w-12 h-12 flex-none rounded-full bg-gray-800" :src="layer.imageUrl" alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm/6 font-semibold text-white">{{ layer.name }}</p>
              <p class="mt-1 text-xs/5 text-gray-400">{{ layer.desc }}</p>
            </div>
          </div>
          <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p class="text-sm/6 text-white">{{ layer.role }}</p>
            <div class="mt-1 flex items-center gap-x-1.5">
              <span class="relative flex h-2 w-2">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-2 w-2 bg-sky-500"></span>
              </span>
              <span class="text-xs/5 text-gray-400">Updating in real-time</span>
<!--              <p class="text-xs/5 text-gray-400">{{ layer.lastUpdate }}</p>-->
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="flex pl-32 pt-16 w-1/2">
    </div>

  </div>
</template>

<script>
export default {
  components: {
  },

  computed: {
  },

  mounted() {
  }
}
</script>
