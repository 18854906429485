<template>
  <div class="py-4 px-5 space-y-2">
    <ul class="list-disc pl-6">
      <li v-for="mention in event.mentions" :key="mention"><b>{{ mention.name }}</b> ({{ mention.type }}) is <i>{{ mention.role }}</i>.</li>
    </ul>
  </div>
</template>


<script>
import useDetailsStore from "@/stores/overview/details"

import { mapState } from 'pinia'

export default {
  components: {

  },

  computed: {
    ...mapState(useDetailsStore, [ 'event' ])
  },

  methods: {
  }
}
</script>
